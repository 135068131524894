<template>
  <v-container>
    <v-row justify="center" class="mt-1">
      <v-col class="text-center pt-5">
        <h4 class="text-h4 font-weight-regular">Service Status</h4>
      </v-col>
    </v-row>

    <v-row justify="center" v-if="loading">
      <v-col>
        <div class="text-center py-15">
          <v-progress-circular indeterminate size="50" color="grey" />
          <div class="py-2">Loading</div>
        </div>
      </v-col>
    </v-row>

    <v-row justify="center" v-else>
      <v-col cols="12" lg="8" xl="6">
        <v-card>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="60px" class="text-center">Status</th>
                  <th>Application</th>
                  <th>Version</th>
                  <th>Uptime</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="app in Object.values(applications)" :key="app.id">
                  <td class="text-center">
                    <v-avatar color="yellow darken-3" size="32" v-if="app.health === 'UP'">
                      <v-icon dark>mdi-arrow-up</v-icon>
                    </v-avatar>
                    <v-avatar color="red" size="32" v-else>
                      <v-icon dark>mdi-arrow-down</v-icon>
                    </v-avatar>
                  </td>
                  <td>{{ app.title }}</td>
                  <td>{{ app.info.version }}</td>
                  <td>{{ calculateUptime(app.info.time) }}</td>
                  <td :key="'refresh_' + refreshKey">
                    <v-btn
                      small
                      outlined
                      color="red"
                      class="ma-2"
                      :loading="refreshing[app.title]"
                      @click.stop="restartApp(app)"
                      v-if="app.health === 'UP' && canRefresh(app)"
                    >
                      Restart
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import startCase from "lodash.startcase";
import * as api from "@/constants/api";
import { serviceNames } from "@/constants/serviceNameMap";

export default {
  name: "Status",
  metaInfo: { title: "Service Status" },
  data() {
    return {
      loading: false,
      applications: {},
      timeout: null,
      refreshing: {},
      refreshKey: 0,
    };
  },
  mounted() {
    this.bootstrap();
    this.timeout = setInterval(() => this.updateStatusAndInfo(), 10000);
  },
  destroyed() {
    clearInterval(this.timeout);
  },
  methods: {
    calculateUptime(deployTime) {
      if (!deployTime) return "";
      return moment(deployTime).fromNow();
    },
    async bootstrap() {
      try {
        this.loading = true;
        await this.fetchData();
      } finally {
        this.loading = false;
        this.updateStatusAndInfo();
      }
    },
    async fetchData() {
      try {
        const applications = {};

        const addApp = ([route, name]) => {
          applications[name] = {
            id: name,
            title: serviceNames[name] || startCase(name),
            infoLink: route.replace("/**", api.ACTUATOR_ROUTES_INFO),
            health: "DOWN",
            info: {
              artifact: name,
            },
          };
        };

        addApp(["/**", "gateway-service"]);
        const data = await this.$axios.$get(api.ACTUATOR_ROUTES);
        Object.entries(data).map(addApp);

        this.$set(this, "applications", applications);
      } catch (err) {
        this.$iziToast.showError(err);
      }
    },
    updateStatusAndInfo() {
      return Promise.all(
        Object.values(this.applications).map(async ({ id, infoLink }) => {
          try {
            const response = await this.$axios.get(infoLink);
            if (!response.data) {
              throw new Error("No Data");
            }
            this.$set(this.applications[id], "health", "UP");
            this.$set(this.applications[id], "info", response.data.build);
          } catch (err) {
            this.$set(this.applications[id], "health", "DOWN");
          } finally {
            //this.$set(this.applications[id], "lastCheckTime", new Date());
          }
        })
      );
    },
    canRefresh(service) {
      const prefix = service.infoLink.replace("/actuator/info", "");
      return this.verifyPrivilege(prefix + api.ACTUATOR_ROUTES_REFRESH, "POST");
    },
    async restartApp(service) {
      try {
        this.refreshing[service.title] = true;
        this.refreshKey++;
        service.prefix = service.infoLink.replace("/actuator/info", "");
        await this.$axios.post(service.prefix + api.ACTUATOR_ROUTES_REFRESH);
        this.$iziToast.success({
          title: "Success",
          message: `${service.title} has been restarted`,
        });
      } catch (err) {
        this.$iziToast.showError(err);
      }
      delete this.refreshing[service.title];
      this.refreshKey++;
    },
  },
};
</script>
